<template>
  <div class="">
    <center-title title="品类数据"></center-title>
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <div class="row-between">
        <div class="row">
          <el-form-item>
            <el-select
              v-model="form.wuliaofl"
              placeholder="物料分类"
              filterable
              style="width: 120px"
            >
              <el-option label="全部" value="0"></el-option>
              <el-option
                v-for="item in Wuliaofenlei"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="form.dept"
              placeholder="部门/角色"
              style="width: 120px"
            >
              <el-option
                v-for="item in Caigoudept"
                :key="item.id"
                :label="item.dname"
                :value="item.dname"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="form.caigouren"
              placeholder="采购人"
              filterable
              style="width: 120px"
            >
              <el-option label="全部" value="0"></el-option>
              <el-option
                v-for="item in Caigoupeople"
                :key="item.id"
                :label="item.true_name"
                :value="item.true_name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="form.date"
              value-format="yyyy-MM-dd"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="primary" class="whtie submit" @click="resetList(1)"
            >筛选</el-button
          >
          <el-button
            type="primary"
            class="whtie submit"
            @click="exportConsumeRecord"
            >导出</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <el-table :data="Productcategory">
      <el-table-column
        align="center"
        prop="name"
        label="品类名称"
        width="140px"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="skutotal"
        label="SKU总数"
        width="120px"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="cgskutotal"
        label="采购SKU数量"
        width="120px"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="cgjetotal"
        label="采购总金额"
        width="120px"
      >
        <template slot-scope="scope">
          <make-priceformat
            :price="scope.row.cgjetotal"
            :subscriptSize="14"
            :firstSize="14"
            :secondSize="14"
            color="#333333"
          ></make-priceformat>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="ddtotal"
        label="订单总条数"
        width="120px"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="dccgsl"
        label="平均单次采购数量"
        width="120px"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="dccgje"
        label="平均单次采购金额"
        width="120px"
      >
        <template slot-scope="scope">
          <make-priceformat
            :price="scope.row.dccgje"
            :subscriptSize="14"
            :firstSize="14"
            :secondSize="14"
            color="#333333"
          ></make-priceformat>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="cgrs"
        label="采购人数"
        width="120px"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="drcgsl"
        label="平均单人采购数量"
        width="120px"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="drcgje"
        label="平均单人采购金额"
        width="120px"
      >
        <template slot-scope="scope">
          <make-priceformat
            :price="scope.row.drcgje"
            :subscriptSize="14"
            :firstSize="14"
            :secondSize="14"
            color="#333333"
          ></make-priceformat>
        </template>
      </el-table-column>
    </el-table>
    <div class="row-center pagination mt30">
      <make-pagination
        @resetList="resetList"
        :orderObject="orderObject"
      ></make-pagination>
    </div>
  </div>
</template>
<script>
import { download } from "@/utils/plugin";
import CenterTitle from "../components/CenterTitle";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1080);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      page_no: 1,
      page_size: 10,
      Productcategory: [],
      Wuliaofenlei: [],
      Caigoupeople: [],
      Caigoudept: [],
      dialogVisible: false,
      uploadDialogVisible: false,
      download: process.env.VUE_APP_DOWNLOAD_URL,
      form: {
        wuliaofl: "",
        dept: "",
        caigouren: "",
        date: [],
        start_time: "",
        end_time: "",
      },
      orderObject: {},
      auditBox: {
        id: "",
        type: 1,
        reject_reason: "",
      },
      uploadPay: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    exportConsumeRecord() {
      this.$api;
      download(
        "/Category/goodsExcel",
        {
          type: this.queryType,
          page_no: this.page_no,
          page_size: this.page_size,
          ...this.form,
          start_time: this.form.date && this.form?.date[0],
          end_time: this.form.date && this.form?.date[1],
        },
        `品类数据${new Date().getTime()}.xlsx`
      );
    },
    onSubmit() {},
    resetList(e) {
      this.page_no = e;
      this.getProductcategory();
    },
    /**
     * @description 获取对账列表
     * @returns void
     */
    getProductcategory() {
      this.$api
        .getProductcategoryApi({
          type: this.queryType,
          page_no: this.page_no,
          page_size: this.page_size,
          ...this.form,
          start_time: this.form.date && this.form?.date[0],
          end_time: this.form.date && this.form?.date[1],
        })
        .then((res) => {
          this.Productcategory = res.data.list;
          this.Caigoupeople = res.data.caigouresult;
          this.Wuliaofenlei = res.data.wuliaoresult;
          this.Caigoudept = res.data.deptresult;
          // console.log(this.Caigoupeople);
          let { lists, count, page_size: size, page_no: page } = res.data;
          this.orderObject = res.data;
          this.orderObject = {
            page,
            size,
            count,
            lists,
            // caigouresult,
          };
        });
    },
    setRmOperate() {
      this.$api.setRmOperateApi(this.auditBox).then((res) => {
        if (res.code == 1) {
          this.resetList(1);
          this.dialogVisible = false;
        }
      });
    },
    resetList(e) {
      this.page_no = e;
      this.getProductcategory();
    },
  },
  created() {
    this.getProductcategory();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
/deep/ .el-form {
  .el-form-item__content,
  .el-input__inner {
    // height: 32px;
    // line-height: 32px;
    // width: 240px;
    border-radius: 0;
  }

  .el-input__icon {
    line-height: 32px;
  }

  .el-date-editor .el-range-separator {
    width: auto;
  }
}

.el-upload {
  // border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  button {
    border-radius: 5px;
    padding: 10px 20px;
  }
}

.el-upload:hover {
  border-color: inherit;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

/deep/ .el-table {
  border: $-solid-border;
  border-bottom: none;

  th {
    background-color: $-color-center;
    font-size: 16px;
    font-weight: normal;
    color: $-color-lighter;
  }

  td {
    &:nth-child(2) {
      .cell {
        padding: 0 10px;
      }
    }

    .cell {
      padding: 0px 20px;
      line-height: 1.3;
      word-break: break-all;
    }
  }

  th,
  td {
    border-right: $-solid-border;

    &:last-child {
      border-right: none;
    }
  }
}
</style>
